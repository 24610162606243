import { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import HistoryIcon from '@mui/icons-material/History';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import UserDetailsTabs from './UserDetailsTabs.style';
import toast from 'react-hot-toast';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { QueryErrorResponse } from '../../../types/UserDetailType';
import { KEY_CONF, URL_CONF, BASE_URL } from '../../../helper/constants';
import referralQueries from '../../../queries/referral';
import { IReferralData } from '../../../interfaces/Referral';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { decryptString, encryptString } from '../../../helper/encryptionHelper';

function ReferralsTab() {
    const classes = UserDetailsTabs();
    const activeUserLibraryData = useSelector((state: RootState) => state.userLibrary.value);
    const [referralData, setReferralData] = useState<IReferralData[]>();
    const [successfulReferral, setSuccessfulReferral] = useState<number>(0);
    const [copiedUrl, setCopiedUrl] = useState<string>('');
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleDetailsError = ({ response }: QueryErrorResponse) => {
        const errorMessage = response && response.errors && response.errors[0] ? response.errors[0].message : 'API failed';
        if (errorMessage) {
            toast.success(errorMessage);
        }
        return true;
    };

    // Query to get the Sumsub details
    const encryptedCode = encryptString(activeUserLibraryData?.self_referral_code ?? '') || '';
    const { data: listData } = useGQLQuery(
        KEY_CONF.REFERRAL_USER_LIST,
        referralQueries.REFERRAL_USER_LIST(encryptedCode),
        {},
        {
            onError: handleDetailsError,
        },
        URL_CONF.ADMIN,
    );

    // Handle copy functionality
    const handleCopy = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    useEffect(() => {
        if (listData && listData.adminUserReferrals) {
            const decryptedData = listData.adminUserReferrals.data.map((referral) => ({
                ...referral,
                user_name: decryptString(referral.user_name),
            }));
            setReferralData(decryptedData);
            let totalReferral = 0;
            decryptedData.forEach((ref) => {
                totalReferral += ref.no_of_transactions;
            });
            setSuccessfulReferral(totalReferral);
        }
    }, [listData]);

    useEffect(() => {
        const selfReferralCode: string = activeUserLibraryData?.self_referral_code || '';
        setCopiedUrl(`${BASE_URL}/signup/${selfReferralCode}`);
    }, [activeUserLibraryData?.self_referral_code]);

    return (

        <div>
            <Box className={classes.container}>
                <Box component="div" className={`${classes.box} ${classes.box1}`}>
                    <Typography variant="h2" component="h2">Become our Partner and Refer</Typography>
                    <Typography variant="body1" component="p" className={classes.paragraph}>
                        {/* Lorem ipsum dolor sit amet consectetur.
                        Massa volutpat vitae risus mauris. Lorem
                        faucibus donec integer habitant amet.
                        Varius ultricies ullamcorper. */}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item className={classes.wrapInput} sm={9}>
                            <TextField size="small" label="" disabled value={copiedUrl} className="bgwhite" fullWidth />
                            {isCopied && <span className={classes.textColour}>Copied Successfully</span>}
                        </Grid>
                        <Grid item sm={3}>
                            <Button className={classes.btnCopy} variant="contained" color="primary" onClick={handleCopy}>
                                <ContentCopyIcon />
                                <Typography variant="h3" component="h3" className={classes.btnText}>
                                    <CopyToClipboard text={copiedUrl}>
                                        <Typography variant="body2" className={classes.copyButton}>Copy Link</Typography>
                                    </CopyToClipboard>
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div" className={`${classes.box} ${classes.box2}`}>
                    <Typography variant="h2" component="h2">
                        Total Successful referrals
                    </Typography>
                    <Typography variant="body2" component="span">
                        { successfulReferral }
                    </Typography>
                </Box>
                <Box component="div" className={`${classes.box} ${classes.box3}`}>
                    <Stack direction="row" alignItems="center" gap={1} className={classes.heading}>
                        <HistoryIcon />
                        <Typography variant="body1">History of Successful referrals</Typography>
                    </Stack>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date of Refferal</TableCell>
                                    <TableCell>User name</TableCell>
                                    <TableCell>No of Successful transactions by this User</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>&nbsp;</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {referralData && referralData.length > 0 && referralData.map((data: IReferralData) => (
                                    <TableRow>
                                        <TableCell>{new Date(parseInt(data.date_of_referral, 10)).toLocaleString()}</TableCell>
                                        <TableCell>{data.user_name}</TableCell>
                                        <TableCell>{data.no_of_transactions}</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                        <TableCell>&nbsp;</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </div>
    );
}

export default ReferralsTab;
