import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IDraftData } from '../../types/DraftDataType';
import { UserAddress, UserPersonalInfo } from '../../types/UserDetailType';
import { IAccountInfoPayout } from '../../interfaces/User';

interface InitialState {
    value: number,
    saveAsDraft: boolean,
    saveAsDraftData: IDraftData,
}

const initialState: InitialState = {
    value: 0,
    saveAsDraft: false,
    saveAsDraftData: {
        basicInfoData: {
            user_info: {
                firstName: '',
                lastName: '',
                contact: '',
                email: '',
                settlementCurrency: '',
                dob: '',
            },
            address: {
                addressLineOne: '',
                addressLineTwo: '',
                country: '',
                city: '',
                state: '',
                postal_code: '',
            },
        },
        accountData: [{
            currency: '',
            accountDescription: '',
            bankingInstruction: '',
            bankingAddress: '',
            institutionNumber: '',
            transitNumber: '',
            accountNumber: '',
            swiftBic: '',
            accountHolderFirstName: '',
            accountHolderLastName: '',
            accountHolderEmail: '',
            beneficiary: '',
            beneficiaryAddress: '',
            phoneNumber: '',
            isManuallyCreated: false,
        }],
    },
};

export const userOnboardingSlice = createSlice({
    name: 'userOnboardingStep',
    initialState,
    reducers: {
        goToNextStep: (state) => {
            const currentStep = state.value;
            if (currentStep < 8) {
                state.value += 1;
            } else if (currentStep === 8) {
                state.value = -1;
            }
        },
        goToPreviousStep: (state) => {
            const currentStep = state.value;
            if (currentStep > 0) {
                state.value -= 1;
            }
        },
        goToCustomStep: (state, action: PayloadAction<number>) => {
            state.value = action.payload;
        },
        resetStep: (state) => {
            state.value = 0;
        },
        updateSaveAsDraft: (state, action: PayloadAction<boolean>) => {
            state.saveAsDraft = action.payload;
        },
        updateBasicInfoData: (state, action: PayloadAction<UserPersonalInfo>) => {
            state.saveAsDraftData = {
                accountData: state.saveAsDraftData.accountData ? { ...state.saveAsDraftData.accountData } : undefined,
                basicInfoData: {
                    address: state.saveAsDraftData.basicInfoData?.address ? { ...state.saveAsDraftData.basicInfoData?.address } : undefined,
                    user_info: { ...action.payload },
                },
            };
        },
        updateAddressInfoData: (state, action: PayloadAction<UserAddress>) => {
            state.saveAsDraftData = {
                accountData: state.saveAsDraftData.accountData ? { ...state.saveAsDraftData.accountData } : undefined,
                basicInfoData: {
                    user_info: state.saveAsDraftData.basicInfoData?.user_info ? { ...state.saveAsDraftData.basicInfoData?.user_info } : undefined,
                    address: { ...action.payload },
                },
            };
        },
        updateUserAccountInfo: (state, action: PayloadAction<IAccountInfoPayout[]>) => {
            state.saveAsDraftData = {
                accountData: { ...action.payload },
                basicInfoData: { ...state.saveAsDraftData.basicInfoData },
            };
        },
        setDraftData: (state, action: PayloadAction<IDraftData>) => {
            state.saveAsDraftData = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    goToNextStep,
    goToPreviousStep,
    resetStep, updateSaveAsDraft,
    updateAddressInfoData, updateUserAccountInfo, updateBasicInfoData, setDraftData, goToCustomStep,
} = userOnboardingSlice.actions;

export default userOnboardingSlice.reducer;
