import { Dispatch, SetStateAction } from 'react';
import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    Button,
    Grid,
    Typography,
    Divider,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import useStyles from './TrackOrderFormModel.style';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Tooltip from '@mui/material/Tooltip';
import { IAdminDashBoard, IUserLibrary } from '../../../../interfaces/User';
import toast from 'react-hot-toast';
import {
    COLOR_DATA, HANDLE_TRANSACTION, STATUS_COLOR, TRADE_ORDER_DATE,
} from '../../../../helper/constants';
import { dobFormatter } from '../../../../helper/format';

interface IInviteProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    openUpdateStatus?: () => void;
    state?: IAdminDashBoard | undefined;
    tradeOrderData?: IUserLibrary;
}

function TrackOrderFormModel({
    open,
    setOpen,
    openUpdateStatus,
    state,
    tradeOrderData,
}: IInviteProps) {
    const classes = useStyles();

    const getStatusColor = (data: string): string => {
        switch (data) {
        case STATUS_COLOR.BUY:
            return COLOR_DATA.BUY;
        case STATUS_COLOR.SELL:
            return COLOR_DATA.SELL;
        case STATUS_COLOR.CANCELLED:
            return COLOR_DATA.CANCEL;
        case STATUS_COLOR.PENDING:
            return COLOR_DATA.PENDING;
        case STATUS_COLOR.COMPLETED:
            return COLOR_DATA.COMPLETE;
        default:
            return '';
        }
    };

    const handleOpenUpdateStatusModel = () => {
        if (openUpdateStatus) {
            openUpdateStatus();
        }
        setOpen(false);
    };

    const handleClickClose = () => {
        setOpen(false);
    };

    const handleTransactionStatus = (data:string | undefined) => {
        if (data === STATUS_COLOR.COMPLETED) {
            return HANDLE_TRANSACTION.COMPLETE;
        }
        if (data === STATUS_COLOR.PENDING) {
            return HANDLE_TRANSACTION.PENDING;
        }
        if (data === STATUS_COLOR.CANCELLED) {
            return HANDLE_TRANSACTION.CANCEL;
        }
        return '';
    };

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(state?.email ? state.email : '');
        } catch (error) {
            toast.error('Unable to copy email');
        }
    };

    return (
        <Box>
            <Dialog fullWidth className={classes.mainDilogBoxSection} open={open}>
                <DialogTitle className={classes.dilogBoxSection}>
                    <Typography variant="h2">Trade Order Form222</Typography>
                    <IconButton aria-label="close" onClick={handleClickClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className={classes.dialogBody}>
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={7}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Ref ID
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.ref_id || tradeOrderData?.ref_id}
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Box>
                                    {/* <Typography className={classes.inBoxTextYellow} variant="subtitle1">Pending</Typography>
                                <Typography className={classes.inBoxTextGreen} variant="subtitle1">Completed</Typography> */}
                                    <Typography
                                        className={getStatusColor(state?.transaction_status || tradeOrderData?.transaction_status || '')}
                                        variant="subtitle1"
                                    >
                                        {handleTransactionStatus(state?.transaction_status) || tradeOrderData?.transaction_status}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Type
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {/* Buy Crypto (Send Fiat) */}
                                    {state?.transaction_type || tradeOrderData?.transaction_type}
                                    {' '}
                                    Crypto
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Selected Crypto
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.crypto_type || tradeOrderData?.crypto_type}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Wallet Address
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.wallet_address || tradeOrderData?.wallet_address}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={5}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Amount
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.amount || tradeOrderData?.amount}
                                    {' '}
                                    <span className={classes.greySubText}>{state?.currency || tradeOrderData?.currency}</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.whiteText} variant="body1">
                                    OR
                                </Typography>
                                <Typography className={classes.blueHead} variant="body1">
                                    OR
                                </Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Quantity of Tokens
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.amount || tradeOrderData?.token}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Phone Number
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.contact || tradeOrderData?.contact}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.copyButtonStyle} variant="body1">
                                    Email
                                    <span>
                                        <Tooltip title="Click to copy" placement="top">
                                            <FileCopyOutlinedIcon
                                            // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                                onClick={handleCopyClick}
                                            />
                                        </Tooltip>
                                    </span>
                                </Typography>
                                <Box className={classes.copyButtonStyle}>
                                    <Typography className={classes.blSubText} variant="body1">
                                        {state?.email || tradeOrderData?.email}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={12}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Preferred method of contact
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.mode_of_contact || tradeOrderData?.mode_of_contact}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            <Grid item xs={6}>
                                <Typography className={classes.blueHead} variant="body1">
                                    Order Date & Time
                                </Typography>
                                <Typography className={classes.blSubText} variant="body1">
                                    {state?.created_at || dobFormatter({ dateString: Number(tradeOrderData?.created_at) })}
                                    ,
                                    {' '}
                                    {state?.time_created_at}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography className={classes.blueHead} variant="body1">
                                    {state?.transaction_status
                                     === STATUS_COLOR.CANCELLED
                                        ? TRADE_ORDER_DATE.CANCELLATION
                                        : TRADE_ORDER_DATE.PENDING_COMPLETION }

                                </Typography>
                                {state?.transaction_status !== STATUS_COLOR.PENDING
                                    ? (
                                        <Typography className={classes.blSubText} variant="body1">
                                            {state?.updated_at || dobFormatter({ dateString: Number(tradeOrderData?.updated_at) })}
                                            { state?.transaction_status !== STATUS_COLOR.PENDING ? ',  ' : null}
                                            {state?.transaction_status !== STATUS_COLOR.PENDING && state?.time_updated_at}
                                        </Typography>
                                    ) : <Typography>NA</Typography>}

                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className={classes.inTabRight}>
                        <Grid container spacing={2} className={classes.sectionBody}>
                            {state?.transaction_status === STATUS_COLOR.CANCELLED
                                ? (
                                    <Grid item xs={12}>
                                        <Typography className={classes.blueHead} variant="body1">
                                            Cancellation Reason
                                        </Typography>
                                        <Typography className={classes.blSubText} variant="body1">
                                            {state?.remarks || tradeOrderData?.remarks}
                                        </Typography>
                                    </Grid>
                                )
                                : null}
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dilogBoxSectionFooter}>
                    <Box className={classes.footerBtnFillBlue}>
                        <Button onClick={handleOpenUpdateStatusModel}>Update Status</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default TrackOrderFormModel;
