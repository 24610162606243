import {
    useQuery,
} from '@tanstack/react-query';
import { URL_CONF, UNAUTHORIZED } from '../helper/constants';
import gqlConfig from '../helper/gqlConfig';
import { IGoogleSSOLoginResponse, IGoogleSSORegisterResponse } from '../interfaces/Auth';
import { IReferralData } from '../interfaces/Referral';
import {
    ISumsubAddStatusReponse, ISumsubDeleteStatusReponse,
    ISumsubDetailsReponse,
    ISumsubTokenReponse,
} from '../interfaces/Sumsub';
import { IPageInfo, ITransactionData } from '../interfaces/Transaction';
import {
    IAdminDashBoard,
    IAllUserData,
    IListTransaction,
} from '../interfaces/User';
import { PlaidAccountDetails } from '../types/AccountType';
import { IDraftDataReponse } from '../types/DraftDataType';
import { CountryType, StateType } from '../types/RegionType';
import { UserCardType, UserDetailsData, UserInfo } from '../types/UserDetailType';
import { IUserInfoDataReponse } from '../types/UserInfoDataType';
import { AxiosError } from 'axios';
import { useLogout } from './useLogout';
import { useNavigate } from 'react-router-dom';
import { PATH_AUTH } from '../routers/path';
import { CurrencyList } from '../interfaces/Currency';
import { fetchLogObj } from '../helper/log';
import userQueries from '../queries/user';
import { getAccessTokenFromLocalStorage } from '../helper/storageHelper';

interface ILoginReponse {
    email: string
    access_token: string
    refresh_token: string
    info: UserInfo
}

interface IUserAddressType {
    address_line_1: string
    address_line_2: string
    country: string
    state: string
    city: string
    postal_code: string
}

interface IBasicInfo {
    first_name: string
    last_name: string
    address: IUserAddressType
    dob: string
    charity_industry: string
    business_type: number
    business_industry: string
    org_name: string
    website: string
    settelment_currency: string
    tax_id: string
    role: string
    user_type: number
    email: string
    contact_country_code: string
    contact: number
    settlement_currency: string
    org_number: string
}
interface IKycInfo {
    name: string
}

interface IAccountData {
    currency: string
    account_description: string
    banking_instruction: string
    banking_address: string
    instution_number: number
    transit_number: number
    account_number: number
    swift_bic: string
    account_holder_firstname: string
    account_holder_lastName: string
    account_holder_email: string
    beneficiary_name: string
}

interface IOnboardingResponse {
    basicInfoData: IBasicInfo,
    kycData: IKycInfo,
    accountData: IAccountData,
}

interface IRefreshTokenResponse {
    access_token: string,
    refresh_token: string
}

interface QueryReturnType {
    data:
    | {
        userTransactions: {
            data: ITransactionData[],
            page_info: IPageInfo,
        },
        adminAllUsers:{
            data: IAllUserData[],
            pageInfo: IPageInfo,
        },
        adminAllUserTransaction:{
            data: IAdminDashBoard[],
            pageInfo: IPageInfo,
        },
        refresh_token: IRefreshTokenResponse,
        login?: ILoginReponse,
        sso_login: IGoogleSSOLoginResponse,
        sso_register: IGoogleSSORegisterResponse,
        register?: ILoginReponse,
        onboarding?: IOnboardingResponse,
        get_draft_data?: IDraftDataReponse,
        get_otc_account_data?:IDraftDataReponse,
        getSumsubDetails?: ISumsubDetailsReponse,
        getSumsubToken?: ISumsubTokenReponse,
        addSumsubStatus?: ISumsubAddStatusReponse,
        updateDeleteStatus?: ISumsubDeleteStatusReponse,
        userInfoData?: IUserInfoDataReponse,
        singleUserDetails?: UserDetailsData,
        adminDashboardData?: UserCardType,
        adminUserTransaction?: {
            data: IListTransaction[],
            pageInfo: IPageInfo,
        },
        fetch_identity_detail_data?: {
            data: PlaidAccountDetails[]
        },
        adminUserReferrals: {
            data: IReferralData[],
            page_info: IPageInfo,
        },
        get_country_list?: CountryType[],
        get_state_list?: StateType[],
        generate_tos_token?: {
            link: string,
            token: string,
        },
        get_user_spherepay_status?: {
            status: string,
        },
        getCurrency?: CurrencyList[],
    }
    | undefined
    isFetching: boolean
    isError: boolean
    error: QueryErrorResponse | null
    refetch: () => void
    // refetch: ({ orgId }?: { [key: string]: string | number }) => void
}
export type QueryReturnData = QueryReturnType['data'];

type QueryErrorResponse = {
    response: {
        errors: {
            message: string
            status: number
        }[]
        status: number
    }
};

type Config =
    | {
        enabled?: boolean
        refetchOnWindowFocus?: boolean
        onSuccess?: (data: any) => void
        onError?: (error: QueryErrorResponse) => boolean
    }
    | undefined;

export const useGQLQuery = (
    key: string[] | string,
    query: string,
    variables = {},
    config?: Config,
    path?: string,
): QueryReturnType => {
    const apiPath = path || URL_CONF.DEFAULT;
    const graphQLClient = gqlConfig(apiPath);
    const logout = useLogout();
    const navigate = useNavigate();

    /**
     * Function to fetch the data using graphQLClient
     * @param
     * @returns data
     */
    const fetchData = async () => {
        try {
            const data = await graphQLClient.request(query, variables);
            // add logs
            const logReq = fetchLogObj(key[0]); // key is string or array of string
            if (logReq && getAccessTokenFromLocalStorage()) {
                const input = JSON.stringify(logReq);
                const logQuery: string = userQueries.CREATE_LOG();
                const defaultGraphqlClient = gqlConfig(URL_CONF.DEFAULT);
                await defaultGraphqlClient.request(logQuery, { user_logs: input });
            }
            return data;
        } catch (error) {
            // Check if the error is due to unauthorized access
            if ((error as AxiosError)?.response?.status === UNAUTHORIZED.code) {
                logout();
                navigate(PATH_AUTH.auth);
                return null;
            }
            throw error;
        }
    };

    const defaultQueryConfig = { refetchOnWindowFocus: false };
    // const accessToken = getAccessTokenFromLocalStorage();
    const queryConfig = {
        ...config,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: config && config.enabled !== undefined ? config.enabled : true,
    };

    return useQuery({
        queryKey: [key],
        queryFn: fetchData,
        ...defaultQueryConfig,
        ...queryConfig,
    });
};
