import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const DashboardStyle = makeStyles((theme:Theme) => ({
    container: {
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '10px',
        },
        background: '#eef8ff',
        maxWidth: '850px',
        margin: '0 auto',
        height: '100%',
        paddingBottom: '100px',
        zIndex: '0',
        position: 'relative',
    },
    flexContainer: {
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingTop: '20px',
        },
        margin: '0 auto',
        paddingTop: '37px',
    },
    content: {
        [theme.breakpoints.between('xs', 'sm')]: {
            maxWidth: '368px',
        },
        maxWidth: '685px',
        margin: '0 auto',
    },
    TransHistory: {
        [theme.breakpoints.between('xs', 'sm')]: {
        },
        background: '#fff',
        boxShadow: '0px 12px 18px -6px #abc3e3',
        // '& .tableContainer': {
        //     overflowX: 'auto',
        //     '& th:first-child': {
        //         [theme.breakpoints.between('xs', 'sm')]: {
        //             paddingLeft: '10px',
        //         },
        //         paddingLeft: '25px',
        //     },
        //     '& td:first-child': {
        //         [theme.breakpoints.between('xs', 'sm')]: {
        //             paddingLeft: '10px',
        //         },
        //         paddingLeft: '25px',
        //     },
        //     '& th': {
        //         [theme.breakpoints.between('xs', 'sm')]: {
        //             padding: '10px 12px',
        //             lineHight: '15px',
        //             whiteSpace: 'nowrap',
        //         },
        //         padding: '13px 15px',
        //         background: '#F2F2F2',
        //         color: '#7E7E7E',
        //     },
        // },
        // '& .sell': {
        //     background: '#DEFFEA',
        //     padding: '4px 19px',
        //     color: '#1E7F41',
        //     borderRadius: '12px',
        // },
        // '& .buy': {
        //     background: '#FFF2DF',
        //     padding: '4px 19px',
        //     color: '#875900',
        //     borderRadius: '12px',
        // },
    },
    headContainer: {
        display: 'flex',
        padding: '20px 20px 12px 20px',
        marginBottom: '0px',
    },
    headLeft: {
        flex: 4,
        paddingTop: '5px',
        '& p': {
            fontWeight: '600',
        },
    },
    headRight: {
        display: 'flex',
        flex: 2,
        '& fieldset': {
            border: '1px solid rgba(198, 198, 198, 1) !important',
        },
    },
    searchBox: {
        '& .MuiOutlinedInput-root': {
            border: '#333 0.5px solid',
            paddingLeft: '10px',
        },
        '& button': {
            padding: '0px',
            '& svg': {
                fontSize: '16px',
                color: '#252525',
            },
        },
        '& input': {
            padding: '4px 8px',
            fontSize: '13px',
        },
    },
    box: {
        '&:hover': {
            transform: 'scale(1.02)',
            transition: '0.2s',
        },
        [theme.breakpoints.between('xs', 'sm')]: {
            padding: '12px',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        padding: '18px',
        borderRadius: '5px',
        marginBottom: '24px',
        boxShadow: '0px 12px 18px -6px #abc3e3',
        cursor: 'pointer',
        '& .image': {
            '& img': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    height: '38px',
                },
            },
            [theme.breakpoints.between('xs', 'sm')]: {
                padding: '8px 10px',
            },
            background: '#E1F0FF',
            border: '#0A2A5B 2px solid',
            padding: '15px 16px',
            borderRadius: '51%',
        },
        '& .arrow': {
            '& img': {
                [theme.breakpoints.between('xs', 'sm')]: {
                    width: '20px',
                },
            },
        },
    },
    text: {
        [theme.breakpoints.between('xs', 'sm')]: {
            paddingLeft: '15px',
        },
        flex: 10,
        paddingLeft: '20px',
        '& h4': {
            [theme.breakpoints.between('xs', 'sm')]: {
                fontSize: '16px',
            },
            color: '#0A2A5B',
            fontSize: '24px',
            fontWeight: '500',
        },
    },
    active: {
        background: 'linear-gradient(to right,  #0a2a5b 0%,#1968b6 100%) !important',
        '& .image': {
            background: 'linear-gradient(to right,  #0a2a5b 0%,#1968b6 100%)',
            border: '#fff 2px solid',
            flex: 1,
            '& img': {
                position: 'relative',
                top: '2px',
            },
        },
        '& .arrow': {
            flex: 1,
            textAlign: 'right',
        },
    },
    textWhite: {
        '& h4': {
            color: '#fff',
        },
    },
    dataGridContainer: {
        overflowX: 'auto',
        width: '100%',
        '& .InovuaReactDataGrid--theme-default-light': {
            border: 'none',
            [theme.breakpoints.between('xs', 'sm')]: {
                width: 'calc(100% + 600px)',
            },
        },
        '& .InovuaReactDataGrid__header-layout': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
            backgroundColor: '#F2F2F2',
        },
        '& .InovuaReactDataGrid__header': {
            backgroundColor: '#F2F2F2',
            borderBottom: 'none',
        },
        '& .InovuaReactDataGrid__column-header': {
            borderLeft: 'none',
            padding: '10px',
        },
        '& .InovuaReactDataGrid__column-header__content': {
            color: '#7E7E7E',
            fontFamily: 'Inter-Medium',
            fontSize: '13px',
            fontWeight: '500',
            '&:nth-child(1)': {
                paddingLeft: '18px',
            },
            [theme.breakpoints.between(1000, 1200)]: {
                fontSize: '10px',
            },
        },
        '& .InovuaReactDataGrid__row': {
            '& .InovuaReactDataGrid__cell': {
                paddingLeft: '18px',
            },
        },
        '& .InovuaReactDataGrid__cell__content': {
            fontSize: '13px',
            padding: '10px',
            '& img': {
                verticalAlign: 'text-top',
                marginRight: '2px',
            },
        },
        '& .InovuaReactDataGrid__cell': {
            backgroundColor: '#fff',
            borderLeft: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
            fontSize: '13px',
            fontFamily: 'Inter-Medium',
            fontWeight: 400,
            verticalAlign: 'inherit',
        },
        '& .InovuaReactDataGrid__row--focused': {},
    },
    btnIconText: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '24%',
        '& img': {
            [theme.breakpoints.between('xs', 'sm')]: {
                width: '55px',
            },
        },
    },
}));

export default DashboardStyle;
