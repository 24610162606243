import { TypeCellProps } from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import '@inovua/reactdatagrid-enterprise/index.css';
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import checked_sign from '../../../assets/checked_sign.svg';
import Pagination from '../../../components/PaginationTable';
import Loader from '../../../components/loader';
import {
    KEY_CONF,
    REACT_DATAGRID_KEY,
    RESULT_PER_PAGE, URL_CONF,
} from '../../../helper/constants';
import { getStatusDetailsColor, statusHandleDetails } from '../../../helper/gridCellHelper';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { IAllUserData } from '../../../interfaces/User';
import userQueries from '../../../queries/userInfo';
import { accountInfoId, handleUserDetails } from '../../../redux/reducer/userLibrary';
import { PATH_ADMINPANEL } from '../../../routers/path';
import { UserAccountInfo } from '../../../types/UserDetailType';
import ActionsMenu from './ActionsMenu';
import useDataGridStyles from './DataGrid.style';

interface ICellData {
    contact : number,
    email: string,
    full_name : string,
    id : string,
    pending_stage : boolean,
    sumsub_verified: boolean,
    teller_verified: boolean,
    is_active: boolean,

     // Assuming ActionsMenu returns ReactNode
}
interface IRowProps {
    value: boolean,
    data?: UserAccountInfo,
}

interface IDataGridProps {
    valuee?: number,
    inputText?:string,
    openDetailPopup?: () => void,
}
// type SuccessCallback = () => void;

function DataGridUserLibrary({ valuee, inputText, openDetailPopup }: IDataGridProps) {
    const classes = useDataGridStyles();
    const navigate = useNavigate();
    const params = useParams();
    const url = PATH_ADMINPANEL.children.library;
    // const activeUserLibraryData: UserAccountInfo | null = useSelector((state: RootState) => state.userLibrary.value);
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [renderData, setRenderData] = useState<IAllUserData[]>([]);
    const [totalCount, setTotalCount] = useState(RESULT_PER_PAGE);
    const [rowData, setRowData] = useState<ICellData | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const indexValue = 0;
    const location = useLocation();
    const dispatch = useDispatch();

    const { data: allUserData, isFetching, refetch } = useGQLQuery(
        KEY_CONF.USER_LIBRARY_DATA,
        userQueries.GET_USERS_INFO(pageSkipValue, RESULT_PER_PAGE, valuee === 0, inputText || ''),
        { },
        {
            refetchOnWindowFocus: false,
        },
        URL_CONF.ADMIN,
    );

    useEffect(() => {
        refetch();
    }, [pageSkipValue, refetch]);

    useEffect(() => {
        setPageSkipValue(0);
        const timeoutId:NodeJS.Timeout = setTimeout(() => {
            refetch();
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [inputText, refetch]);

    useEffect(() => {
        if (allUserData) {
            const { adminAllUsers } = allUserData;
            if (adminAllUsers?.data) {
                setRenderData(adminAllUsers?.data);
                const { pageInfo } = allUserData.adminAllUsers;
                setTotalCount(pageInfo.total_count);
                const totalPage = (pageInfo && pageInfo.total_count)
                    ? Math.ceil((pageInfo?.total_count)
                        ? (pageInfo.total_count / RESULT_PER_PAGE) : 0) : 0;
                setTotalPages(totalPage); // set pageInfo for handling pagination
            }
            setLoading(false);
        }
    }, [allUserData]);

    const handleView = (data: UserAccountInfo) => {
        // if (openDetailPopup) {
        //     openDetailPopup();
        // }
        if (url && data && location.pathname === `${url}`) {
            navigate(`${url}/${data?.id}`);
        }
    };

    const sumSunHandle = (value:boolean) => {
        if (value) {
            return <img src={checked_sign} alt="check_sign" />;
        }

        return null;
    };

    const tellerHandle = (value:boolean) => {
        if (value) {
            return <img src={checked_sign} alt="check_sign" />;
        }

        return null;
    };
    const columns = [
        {
            name: 'full_name',
            header: 'User Name',
            maxWidth: 200,
            defaultFlex: 1,
            render: (data: IRowProps) => (
                <span
                    onClick={() => { handleView(data?.data as UserAccountInfo); }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleView(data?.data as UserAccountInfo);
                        }
                    }}
                >
                    {data?.data?.full_name as string}
                </span>
            ),
        },
        {
            name: 'id', header: 'User ID', maxWidth: 1000, defaultFlex: 1,
        },
        {
            name: 'email', header: 'User Email', maxWidth: 1000, defaultFlex: 1,
        },
        {
            name: 'contact', header: 'Phone Number', maxWidth: 1000, defaultFlex: 1,
        },
        {
            name: 'sumsub_verified',
            header: 'Sumsub_verified',
            maxWidth: 1000,
            defaultFlex: 1,
            render: ({ value }: { value: boolean }) => (
                <span>
                    {sumSunHandle(value)}
                </span>
            ),
        },
        {
            name: 'teller_verified',
            header: 'Account/Plaid Verification',
            maxWidth: 1000,
            defaultFlex: 1,
            render: (data: IRowProps) => (
                <span>
                    {tellerHandle(data.value)}
                </span>
            ),
        },
        {
            name: `${valuee === indexValue ? 'pending_stage' : 'is_active'}`,
            header: 'Status',
            maxWidth: 1000,
            defaultFlex: 1,
            // eslint-disable-next-line react/no-unused-prop-types
            render: valuee === indexValue ? ({ value }: { value: string }) => (
                <div className={value === 'verified' ? getStatusDetailsColor('verified') : getStatusDetailsColor('pending')}>
                    {value === 'verified' ? statusHandleDetails('verified') : statusHandleDetails('pending')}
                </div>
            ) : (data: IRowProps) => (
                <div className={getStatusDetailsColor('Inactive')}>
                    {data?.value === false ? statusHandleDetails('In Active') : ''}
                </div>
            ),
        },
        {
            name: 'is_actives',
            header: 'Actions',
            maxWidth: 1000,
            defaultFlex: 1,
            render: () => (
                <ActionsMenu
                    rowData={rowData}
                    refetch={refetch}
                    setLoading={setLoading}
                    valuee={valuee}
                    openDetailPopup={openDetailPopup || (() => { })}
                />
            ),
        },
    ];

    const handlePageChange = (page: number) => {
        const skip = (page - 1) * RESULT_PER_PAGE;
        setPageSkipValue(skip);
    };

    const gridStyle = { minHeight: 540 };

    const dataFilter = () => {
        if (valuee === 0) {
            const dtata = renderData.filter((itemss) => itemss.is_active === true);
            return dtata;
        } if (valuee === 1) {
            const dtata = renderData.filter((itemss) => itemss.is_active === false);
            return dtata;
        }
        return [];
    };

    const handleCellClick = (event: any, cellProps: TypeCellProps) => {
        const data: ICellData = cellProps.data as ICellData;
        if (cellProps?.name === 'full_name' && data?.id && !params?.userId) {
            const rowId = data.id;
            navigate(`${url}/${rowId}`);
        }
        // localStorage.setItem('id', data?.id);
        dispatch(accountInfoId(data || {}));
        dispatch(handleUserDetails('userLibrary' || ''));
        setRowData(data);
    };

    return (
        <>
            <Box className={classes.dataTableStyle}>
                {(isFetching || loading) && <Loader />}
                <ReactDataGrid
                    idProperty="id"
                    columns={columns}
                    rowHeight={77}
                    headerHeight={46}
                    dataSource={dataFilter()}
                    reorderColumns={false}
                    style={gridStyle}
                    licenseKey={REACT_DATAGRID_KEY}
                    onCellClick={handleCellClick}
                />
            </Box>
            <Pagination
                totalCount={totalCount}
                totalPages={totalPages}
                inputText={inputText}
                handlePageChange={handlePageChange}
                resultPerPage={RESULT_PER_PAGE}
            />
        </>
    );
}

export default DataGridUserLibrary;
