import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import useDashboardStyles from './Dashboard.style';
import
{
    BarChart, Bar, Cell, CartesianGrid, XAxis, YAxis, Tooltip,
}
    from 'recharts';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ChartProps {
    openChart: boolean,
    handleClose: VoidFunction
  }

const chartColor = ['#53B175', '#F6CA19', '#1968B6', '#DD6E55', '#9580C5'];

const data = [
    {
        name: 'USD',
        currency: '80000',
    },
    {
        name: 'CAD',
        currency: '70000',
    },
    {
        name: 'EUR',
        currency: '60000',
    },
    {
        name: 'MXN',
        currency: '40000',
    },
    {
        name: 'BRL',
        currency: '55000',
    },
];

function AmountTransactedChart({ openChart, handleClose }: ChartProps) {
    const classes = useDashboardStyles();
    return (
        <div>
            <Dialog
                open={openChart}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.chartPoupStyle}
            >
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.tittleCorssStyle}>
                            <Typography variant="h2">Amount Transacted</Typography>
                            <span><CloseIcon onClick={handleClose} /></span>
                        </div>
                        <BarChart width={700} height={420} barSize={60} data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis
                                tickFormatter={(value) => new Intl.NumberFormat('en-US').format(value)}
                            />
                            <Tooltip
                                formatter={(value: number) => [
                                    new Intl.NumberFormat('en-US').format(value),
                                ]} // Custom formatter to include both value and name
                            />
                            {/* Render each bar with dynamic fill color */}
                            <Bar dataKey="currency">
                                {data.map((entry, index) => (
                                    <Cell key={entry.name} fill={chartColor[index % chartColor.length]} />
                                ))}
                            </Bar>
                        </BarChart>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AmountTransactedChart;
