import { gql } from 'graphql-request';
import { addEscapeCharacters } from '../helper/validators';

// admin dashboard data.
const GET_ADMIN_DASHBOARD = (
    skip: number,
    take: number,
    searchText?: string,
    transactionStatus?:string,
    startDate?: string,
    endDate?: string,
) => gql`
    query {
        adminAllUserTransaction(
          skip:${skip}, 
          take:${take}, 
          filter: {
            ${searchText ? `ref_id: "${searchText}"` : ''}
            ${searchText && transactionStatus ? ',' : ''}
            ${transactionStatus ? `transaction_status: ${transactionStatus}` : ''}
          },
          ${startDate && endDate ? `date: { ${startDate ? `start_date: "${startDate}"` : ''}, ${endDate ? `end_date: "${endDate}"` : ''} }` : ''}
          ) {
          data {
            amount
            id
            crypto_type
            transaction_type
            ref_id
            transaction_status
            wallet_address
            mode_of_contact
            created_at
            updated_at
            remarks
            user {
              full_name
              email
              contact_country_code
              contact
            }
          }
          pageInfo {
            skip
            take
            has_next_page
            total_count
          }
        }
      }
`;

const GET_ADMIN_DASHBOARD_TRANSACTION = (
    skip:number,
    take: number,
    searchText?:string,
    transactionStatus?:string,
    startDate?: string,
    endDate?: string,
) => gql`
query {
  adminAllUserTransaction(
    skip:${skip}, take:${take}
    filter: {
      ${searchText ? `search_keyword: "${searchText}"` : ''}
       ${searchText && transactionStatus ? ',' : ''}
      ${transactionStatus ? `transaction_status: ${transactionStatus}` : ''}},
      ${startDate && endDate ? `date: { ${startDate ? `start_date: "${startDate}"` : ''}, ${endDate ? `end_date: "${endDate}"` : ''} }` : ''}
  ) {
    pageInfo {
      take
      skip
      total_count
      has_next_page
    }
    data {
      id
      user_id
      created_at
      ref_id
      amount
      currency
      remarks
      mode_of_contact
      crypto_type
      transaction_type
      updated_at
      transaction_status
      email
      full_name
      contact_country_code
      contact
    }
  }
}`;

const UPDATE_ADMIN_STATUS = (transaction_id:string, transaction_status:string, remarks:string) => gql`
mutation {
  adminChangeTransactionStatus(
    transaction_id: ${addEscapeCharacters(transaction_id)}
    transaction_status: ${transaction_status}
    ${remarks && transaction_status === 'CANCELLED' ? `remarks: ${addEscapeCharacters(remarks)}` : 'remarks: ""'}
  ) {
    success
    message
  }
}
`;

const GET_ADMIN_USER_TRANSACTION = (userid: string, skip: number, take: number) => gql`
  query {
    adminUserTransaction(user_id: "${userid}", skip: ${skip}, take: ${take}) {
      data {
        id
        token
        transaction_type
        transaction_status
        amount
        currency
        ref_id
        crypto_type
        wallet_address
        mode_of_contact
        created_at
        updated_at
        remarks
      }
      pageInfo {
        skip
        take
        has_next_page
        total_count
      }
    }
  }
`;

const ADMIN_CHANGE_STATUS = (userid: string, isActive: boolean) => gql`
  mutation {
    adminChangeUserStatus(
      user_id: "${userid}"
      is_active: ${isActive}
    ) {
      message
      success
    }
  }
`;

const ADMIN_CHANGE_VERIFIED = (userid: string, isVerified: boolean) => gql`
  mutation {
    adminChangeUserStatus(
      user_id: "${userid}"
      is_verified: ${isVerified}
    ) {
      message
      success
    }
  }
`;

const SINGLE_USER_DETAILS = (userid: string) => gql`
query {
  singleUserDetails(user_id: "${userid}") {
    id
    role
    user_type
    full_name
    self_referral_code
    transaction_status
    is_active
    email
    dob
    contact
    settelment_currency
    sumsub_verified
    teller_verified
    updated_at
    first_name
    last_name
    account_holder_first_name
    pending_stage
    contact_country_code
    address_line_1
    address_line_2
    country
    state
    city
    postal_code
    is_manually_created
    currency
    account_description
    bank_address
    institute_number
    transit_number
    account_number
    swift_bic
    account_holder_first_name
    account_holder_last_name
    account_holder_email
    beneficiary_name
    beneficiary_address
  }
}
`;

const DASHBOARD_CARD_DATA = (startDate: string, endDate: string) => gql`
  query {
    adminDashboardData
    ${startDate && endDate ? `(date: { ${startDate ? `start_date: "${startDate}"` : ''}, ${endDate ? `end_date: "${endDate}"` : ''} })` : ''}
    {
      total_amount
      active_users
      transactions
      refering_partners
    }
  }
`;

export default {
    GET_ADMIN_DASHBOARD,
    UPDATE_ADMIN_STATUS,
    GET_ADMIN_DASHBOARD_TRANSACTION,
    GET_ADMIN_USER_TRANSACTION,
    SINGLE_USER_DETAILS,
    ADMIN_CHANGE_STATUS,
    ADMIN_CHANGE_VERIFIED,
    DASHBOARD_CARD_DATA,
};
