import {
    Box,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import React, {
    ChangeEvent, FormEvent, useState,
} from 'react';
import tradeOrderCss from './TradeOrder.style';
import { ITraderOrder } from '../../../interfaces/User';
import { ERRORS } from '../../../helper/validationErrors';
import TradeForm from './TradeForm';
import ModalSubmit from './ModalSubmit';
import { emailValidator, numberValidator } from '../../../helper/validators';
import { Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { PATH_OTC } from '../../../routers/path';

function Tradeorder() {
    const classes = tradeOrderCss();
    const [open, setOpen] = React.useState(false);
    const [tradeState, setTradeState] = useState<ITraderOrder>({
        tradeAction: 'buy',
        cryptoType: '',
        walletAddress: '',
        tradeAmount: '',
        tradeToken: '',
        phoneNumber: '',
        email: '',
        blockchain: '',
        contactMethod: '',
    });
    const [errorState, setErrorState] = useState<ITraderOrder>({
        tradeAction: '',
        cryptoType: '',
        walletAddress: '',
        tradeAmount: '',
        tradeToken: '',
        phoneNumber: '',
        email: '',
        contactMethod: '',
        blockchain: '',
    });

    const validateFields = () => {
        // it is being on the submission of the form;
        let transactionEmailError = '';
        let tradeAmountError = '';
        let tradeTokenError = '';

        // Email validations
        if (tradeState.email && !emailValidator(tradeState.email)) {
            transactionEmailError = ERRORS.EMAIL_ERROR;
        } else if (!tradeState.email) {
            transactionEmailError = ERRORS.MANDATORY_FIELD;
        }

        // Numeric validation for trade amount
        if (tradeState.tradeAmount && numberValidator(+tradeState.tradeAmount)) {
            tradeAmountError = ERRORS.NUMERIC_FIELD_ERROR;
        } else if (!tradeState.tradeToken && !tradeState.tradeAmount) {
            tradeAmountError = ERRORS.MANDATORY_FIELD;
        }

        // Numeric validation for trade quantity of tokens
        if (tradeState.tradeToken && numberValidator(+tradeState.tradeToken)) {
            tradeTokenError = ERRORS.NUMERIC_FIELD_ERROR;
        } else if (!tradeState.tradeToken && !tradeState.tradeAmount) {
            tradeTokenError = ERRORS.MANDATORY_FIELD;
        }

        setErrorState({
            tradeAction: !tradeState.tradeAction ? ERRORS.MANDATORY_FIELD : '',
            cryptoType: !tradeState.cryptoType ? ERRORS.MANDATORY_FIELD : '',
            blockchain: !tradeState.blockchain ? ERRORS.MANDATORY_FIELD : '',
            walletAddress: !tradeState.walletAddress ? ERRORS.MANDATORY_FIELD : '',
            phoneNumber: !tradeState.phoneNumber ? ERRORS.MANDATORY_FIELD : '',
            email: transactionEmailError,
            contactMethod: !tradeState.contactMethod ? ERRORS.MANDATORY_FIELD : '',
            tradeAmount: tradeAmountError,
            tradeToken: tradeTokenError,
        });

        // Mandatory check for all fields
        if (!tradeState.tradeAction || !tradeState.cryptoType || !tradeState.blockchain
            || (!tradeState.tradeAmount && !tradeState.tradeToken) || !tradeState.walletAddress
            || !tradeState.phoneNumber || !tradeState.email || !tradeState.contactMethod) {
            return false;
        }

        return true;
    };

    const handleTextfieldChange = (event: SelectChangeEvent) => {
        // on the change of textField it is being called and update the state.
        const { name, value } = event.target;
        setTradeState({
            ...tradeState,
            [name]: value,
        });
        // Clear error state
        if (name === 'tradeAmount' || name === 'tradeToken') {
            setErrorState({
                ...errorState,
                tradeAmount: '',
                tradeToken: '',
            });
        } else {
            setErrorState({
                ...errorState,
                [name]: '',
            });
        }
    };

    const handleListItemChange = (event: SelectChangeEvent) => {
        // it is being called after selecting from the select dropdown.
        const { name, value } = event.target as HTMLInputElement;
        setTradeState({
            ...tradeState,
            [name]: value,
        });
        // Clear error state
        setErrorState({
            ...errorState,
            [name]: '',
        });
    };

    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        // it is being called checked value is updated.
        const { name, checked } = event.target;
        setTradeState({
            ...tradeState,
            contactMethod: checked ? name : '',
        });
        // Clear error state
        setErrorState({
            ...errorState,
            contactMethod: '',
        });
    };

    // called to close the modal
    const handleClose = () => setOpen(false);

    const handleContactChange = (value: string) => {
        // on the change of the contact value it is being called.
        setTradeState({
            ...tradeState,
            phoneNumber: value !== null ? value : '',
        });
        // Clear error state
        setErrorState({
            ...errorState,
            phoneNumber: '',
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        // it is being called on the submitation of the form.
        e.preventDefault();
        validateFields();
    };

    return (
        <div>
            <Box className={classes.formHeader}>
                <Typography variant="h4" component="h4">Trade Order Form</Typography>
                <Box className={classes.dashboardLink}>
                    <Link to={PATH_OTC.dashboard}>
                        <span><KeyboardBackspaceOutlinedIcon /></span>
                        Back to Dashboard
                    </Link>
                </Box>
            </Box>
            <Box className={classes.container}>
                <Box component="div" className={classes.inputAlign}>
                    <TradeForm
                        tradeState={tradeState}
                        errorState={errorState}
                        handleTextfieldChange={handleTextfieldChange}
                        handleListItemChange={handleListItemChange}
                        handleContactChange={handleContactChange}
                        handleSubmit={handleSubmit}
                        handleCheckboxChange={handleCheckboxChange}
                    />
                </Box>
            </Box>
            <ModalSubmit
                open={open}
                handleClose={handleClose}
            />
        </div>
    );
}

export default Tradeorder;
