import { useNavigate } from 'react-router-dom';
import { KEY_CONF, REACT_DATAGRID_KEY, RESULT_PER_PAGE } from '../../../helper/constants';
import { dateFormatter } from '../../../helper/format';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { ITransactionData } from '../../../interfaces/Transaction';
import transactionsQueries from '../../../queries/transactions';
import { PATH_OTC } from '../../../routers/path';
import DashboardStyle from './Dashboard.style';
import OtherServices from '../otherservices';
import {
    ChangeEvent, useDeferredValue, useEffect, useState,
} from 'react';
import toast from 'react-hot-toast';
import {
    Box, Grid, IconButton, Stack, TextField, Typography,
} from '@mui/material';
import Pagination from '../../../components/PaginationTable';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import coin from '../../../assets/coinn.svg';
import Setting from '../../../assets/Setting.svg';
import HistoryIcon from '@mui/icons-material/History';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import USD from '../../../assets/USD.png';

const columns = [
    {
        name: 'id',
        header: 'Id',
        defaultVisible: false,
        resizable: false,
    },
    {
        name: 'created_at',
        defaultFlex: 1,
        // minWidth: 160,
        header: 'Date of Transaction',
        resizable: false,
    },
    {
        name: 'ref_id',
        defaultFlex: 1,
        // minWidth: 120,
        header: 'Ref ID',
        resizable: false,
    },
    {
        name: 'currency',
        header: 'Currency',
        defaultFlex: 1,
        // minWidth: 100,
        resizable: false,
        render: (data: any) => (
            <span>
                <img src={USD} alt="unitedState" />
                {' '}
                {data.value}
            </span>
        ),
    },
    {
        name: 'amount',
        header: 'Amount/Token',
        defaultFlex: 1,
        // minWidth: 130,
        resizable: false,
        render: ({ value }: { value: string | number }) => (
            <span>
                {/* $ */}
                {value.toLocaleString()}
            </span>
        ),
    },
    {
        name: 'crypto_type',
        defaultFlex: 1,
        // minWidth: 150,
        header: 'Type of Crypto',
        resizable: false,
    },
    {
        name: 'transaction_type',
        header: 'Transaction Type',
        defaultFlex: 1,
        // minWidth: 150,
        render: ({ value }: { value: string }) => <span className={value === 'Buy' ? 'buy' : 'sell'}>{value}</span>,
        resizable: false,
    },
];

const gridStyle = { minHeight: 550 };

function Dashboard() {
    const classes = DashboardStyle();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState<string>('');
    const deferredQuery = useDeferredValue(search);
    const [totalCount, setTotalCount] = useState(0);
    const [transactionData, setTransactionData] = useState<ITransactionData[]>([]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // Query to fetch Users Transactions Details------------------
    const {
        data, refetch, error, isError,
    } = useGQLQuery(
        [KEY_CONF.USER_TRANSACTIONS],
        transactionsQueries.GET_USER_TRANSACTIONS(pageSkipValue, RESULT_PER_PAGE, search),
        {},
        {
            enabled: false,
        },
    );

    useEffect(() => {
        if (isError) {
            toast.error(error?.response?.errors[0]?.message || '');
        }
    }, [isError, error?.response?.errors]);

    // During Search & Pagination value changes refetch data
    useEffect(() => {
        refetch();
    }, [pageSkipValue, deferredQuery, refetch]);

    // handling API Data --------------------------------
    useEffect(() => {
        if (data) {
            const { userTransactions } = data;
            const responseData = userTransactions?.data?.map((transaction) => {
                const date = dateFormatter({ dateString: transaction.created_at }); // Convert timestamp to date string
                return {
                    ...transaction,
                    created_at: date,
                };
            });
            setTransactionData(responseData);
            const { page_info: pageInfo } = userTransactions;
            setTotalCount(pageInfo.total_count);
            const totalPage = (pageInfo && pageInfo.total_count) ? Math.ceil(pageInfo.total_count / RESULT_PER_PAGE) : 0;
            setTotalPages(totalPage); // set pageInfo for handling pagination
        }
    }, [data]);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    const handlePageChange = (page: number) => {
        const skip = (page - 1) * RESULT_PER_PAGE;
        setPageSkipValue(skip);
    };

    return (
        <>
            <Box className={classes.container}>
                <Box className={classes.flexContainer}>
                    <Box className={classes.content}>
                        <Grid container className={`${classes.box} ${classes.active}`} onClick={() => navigate(PATH_OTC.tradeorder)}>
                            <Grid item xs={12} className={classes.btnIconText}>
                                <img src={coin} alt="" />
                                <Box className={`${classes.text} ${classes.textWhite}`}>
                                    <Typography variant="h4" component="h4">Concierge OTC Service</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container className={classes.box} onClick={handleOpen}>
                            <Grid item xs={12} className={classes.btnIconText}>
                                <img src={Setting} alt="" />
                                <Box className={classes.text}>
                                    <Typography variant="h4" component="h4">Other Services</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {/* <Box className="box active">
                            <Box className="image">
                                <img src={coin} alt="" />
                            </Box>
                            <Box className="text">
                                <Typography variant="h4" component="h4">OTC Trading Service</Typography>
                            </Box>
                            <Box className="arrow" onClick={() => navigate(PATH_OTC.tradeorder)}>
                                <img src={arrow} alt="" />
                            </Box>
                        </Box>
                        <Box className="box ">
                            <Box className="image">
                                <img src={Setting} alt="" />
                            </Box>
                            <Box className="text">
                                <Typography variant="h4" component="h4">Other Services</Typography>
                            </Box>
                            <Box className="arrow">
                                <img src={blackArrow} alt="" />
                            </Box>
                        </Box> */}
                        <Box className={classes.TransHistory}>
                            <Box className={classes.headContainer}>
                                <Box className={classes.headLeft}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <HistoryIcon />
                                        <Typography variant="body1">History of Transactions</Typography>
                                    </Stack>
                                </Box>
                                <Box className={classes.headRight}>
                                    <Stack className={classes.searchBox} direction="row" alignItems="center" gap={1}>
                                        <TextField
                                            size="small"
                                            fullWidth
                                            id="standard-bare"
                                            variant="outlined"
                                            color="info"
                                            placeholder="Search"
                                            value={search}
                                            onChange={handleSearch}
                                            InputProps={{
                                                startAdornment: (
                                                    <IconButton>
                                                        <SearchOutlinedIcon />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </Stack>
                                </Box>
                            </Box>
                            <Box className={classes.dataGridContainer}>
                                <ReactDataGrid
                                    idProperty="id"
                                    dataSource={transactionData}
                                    columns={columns}
                                    rowHeight={50}
                                    headerHeight={50}
                                    style={gridStyle}
                                    showColumnMenuTool={false}
                                    reorderColumns={false}
                                    showHoverRows={false}
                                    showCellBorders="horizontal"
                                    showZebraRows={false}
                                    sortable={false}
                                    activateRowOnFocus={false}
                                    showActiveRowIndicator={false}
                                    licenseKey={REACT_DATAGRID_KEY}
                                    // loading={isFetching}
                                />
                            </Box>
                            <Pagination
                                totalCount={totalCount}
                                totalPages={totalPages}
                                handlePageChange={handlePageChange}
                                resultPerPage={RESULT_PER_PAGE}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <OtherServices
                open={open}
                handleClose={handleClose}
            />
        </>
    );
}

export default Dashboard;
