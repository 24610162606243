/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { TypeCellProps } from '@inovua/reactdatagrid-community/types';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ChangeEvent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import BRL from '../../../assets/BRL.png';
import CAD from '../../../assets/CAD.png';
import EUR from '../../../assets/EUR.png';
import MXN from '../../../assets/MXN.png';
import USD from '../../../assets/USD.png';
import CustomDataGrid from '../../../components/CustomDataGrid';
import Pagination from '../../../components/PaginationTable';
import Loader from '../../../components/loader';
import {
    COLOR_DATA,
    KEY_CONF, RESULT_PER_PAGE,
    ROUTING_ADMIN_PAGE,
    STATUS_COLOR,
    URL_CONF,
} from '../../../helper/constants';
import { dateFormatter, timeFormatter } from '../../../helper/format';
import { useGQLQuery } from '../../../hooks/useGQLQuery';
import { IAdminDashBoard } from '../../../interfaces/User';
import adminDashboard from '../../../queries/adminDashboard';
import { UserCardType } from '../../../types/UserDetailType';
import TrackOrderFormModel from '../../userdetails/components/trackorderformmodel';
import UpdateTransactionStatusModel from '../../userdetails/components/updatetransactionstatusmodel';
import ActionsMenu from '../datagrid/ActionsMenu';
import useDashboardStyles from './Dashboard.style';
// import { PATH_ADMINPANEL } from '../../../routers/path';
import { useNavigate } from 'react-router-dom';
import {
    Bar,
    BarChart,
    Cell, XAxis,
} from 'recharts';
import AmountTransactedChart from './AmountTransactedChart';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IRowProps {
    value: number;
    rowIndex?: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const {
        children, value, index, ...other
    } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const chartColor = ['#53B175', '#F6CA19', '#1968B6', '#DD6E55', '#9580C5'];

const chartData = [
    {
        name: 'USD',
        currency: '80000',
    },
    {
        name: 'CAD',
        currency: '70000',
    },
    {
        name: 'EUR',
        currency: '60000',
    },
    {
        name: 'MXN',
        currency: '40000',
    },
    {
        name: 'BRL',
        currency: '55000',
    },
];

function AdminDashboard() {
    const classes = useDashboardStyles();
    const [value, setValue] = useState(0);
    const [isDetailPopupOpen, setIsDetailPopupOpen] = useState(false);
    const [isUpdateStatusPopupOpen, setIsUpdateStatusPopupOpen] = useState(false);
    const [inputText, setInputText] = useState('');
    const [state, setState] = useState<IAdminDashBoard>();
    const [pageSkipValue, setPageSkipValue] = useState(0);
    const [renderData, setRenderData] = useState<IAdminDashBoard[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [handleAPIcall, setHandleAPIcall] = useState(false);
    const [adminCardData, setAdminCardData] = useState<UserCardType>({
        total_amount: 0, active_users: 0, transactions: 0, refering_partners: 0,
    });
    const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [, setIsCorrectDate] = useState<boolean>(false);
    // const [isCorrectDate, setIsCorrectDate] = useState<boolean>(false);
    // const url = PATH_ADMINPANEL.children.library;
    const navigate = useNavigate();
    // const location = useLocation();
    // const dispatch = useDispatch();

    const [openChart, setOpenChart] = useState(false);

    /**
     * To open the chart while clicking on Amount Transacted
     */
    const handleClickOpen = () => {
        setOpenChart(true);
    };

    /**
     * Closes the chart.
     */
    const handleClose = () => {
        setOpenChart(false);
    };

    let status = '';
    if (value === 0) {
        status = '';
    }
    if (value === 1) {
        status = STATUS_COLOR.PENDING;
    }
    if (value === 2) {
        status = STATUS_COLOR.COMPLETED;
    }
    if (value === 3) {
        status = STATUS_COLOR.CANCELLED;
    }

    /**
     * Provides color for different status data.
     * @param {string} data
     * @returns string || ''
     */
    const getStatusColor = (data: string): string => {
        switch (data) {
        case STATUS_COLOR.BUY:
            return COLOR_DATA.BUY;
        case STATUS_COLOR.SELL:
            return COLOR_DATA.SELL;
        case STATUS_COLOR.CANCELLED:
            return COLOR_DATA.CANCEL;
        case STATUS_COLOR.PENDING:
            return COLOR_DATA.PENDING;
        case STATUS_COLOR.COMPLETED:
            return COLOR_DATA.COMPLETE;
        default:
            return '';
        }
    };

    const { data: AdminData, isFetching, refetch } = useGQLQuery(
        KEY_CONF.ADMIN_DASHBOARD,
        adminDashboard.GET_ADMIN_DASHBOARD_TRANSACTION(
            pageSkipValue,
            RESULT_PER_PAGE,
            inputText || '',
            status,
            selectedStartDate?.toISOString(),
            selectedEndDate?.toISOString(),
        ),
        {},
        {
            refetchOnWindowFocus: false,
        },
        URL_CONF.ADMIN,
    );

    const startDate = selectedStartDate?.toISOString() || '';
    const endDate = selectedEndDate?.toISOString() || '';
    const { data: cardData, isFetching: cardFetching, refetch: cardRefetch } = useGQLQuery(
        KEY_CONF.ADMIN_CARD_DATA,
        adminDashboard.DASHBOARD_CARD_DATA(startDate, endDate),
        {},
        {
            refetchOnWindowFocus: false,
        },
        URL_CONF.ADMIN,
    );

    useEffect(() => {
        if (cardData && cardData?.adminDashboardData) {
            setAdminCardData(cardData?.adminDashboardData);
        }
    }, [cardData]);

    useEffect(() => {
        if (handleAPIcall) {
            refetch();
            setHandleAPIcall(false);
        }
    }, [handleAPIcall, refetch]);

    useEffect(() => {
        refetch();
    }, [pageSkipValue, refetch]);

    useEffect(() => {
        // During this api call is restricted .
        if (selectedStartDate?.toISOString()
            && selectedEndDate?.toISOString()
            && new Date(selectedStartDate?.toISOString()) <= new Date(selectedEndDate?.toISOString())) {
            refetch();
            cardRefetch();
        }
    }, [selectedStartDate, selectedEndDate, cardRefetch, refetch]);

    useEffect(() => {
        setPageSkipValue(0);
        const timeoutId: NodeJS.Timeout = setTimeout(() => {
            refetch();
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        };
    }, [inputText, refetch]);

    useEffect(() => {
        refetch();
        setPageSkipValue(0);
    }, [value, refetch]);

    useEffect(() => {
        if (AdminData) {
            const { adminAllUserTransaction } = AdminData;
            const resultOneObj: IAdminDashBoard[] = adminAllUserTransaction?.data.map(({
                amount, currency, crypto_type, transaction_type, transaction_status,
                ref_id, full_name, email, contact, wallet_address,
                mode_of_contact, id,
                created_at,
                updated_at,
                user_id,
                remarks,
            }) => ({
                id,
                amount,
                currency,
                ref_id,
                user_id,
                transaction_type,
                crypto_type,
                transaction_status,
                wallet_address,
                mode_of_contact,
                created_at: dateFormatter({ dateString: created_at || '' }),
                updated_at: dateFormatter({ dateString: updated_at || '' }),
                time_created_at: timeFormatter({ timeString: created_at || '' }),
                time_updated_at: timeFormatter({ timeString: updated_at || '' }),
                remarks,
                full_name,
                email,
                contact,

            }));
            setRenderData(resultOneObj);
            if (adminAllUserTransaction) {
                const { pageInfo } = adminAllUserTransaction;
                setTotalCount(pageInfo.total_count);
                const totalPage = (pageInfo && pageInfo.total_count)
                    ? Math.ceil((pageInfo?.total_count)
                        ? (pageInfo.total_count / RESULT_PER_PAGE) : 0) : 0;
                setTotalPages(totalPage); // set pageInfo for handling pagination
            }
        }
    }, [AdminData]);

    /**
     * On the change of tab, it sets the inputText value and the new tab value.
     * @param {React.SyntheticEvent} event
     * @param {number} newValue
     */
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setInputText('');
        setValue(newValue);
    };

    /**
     * Handles the change event of an input text field.
     * @param {ChangeEvent<HTMLInputElement>} e
     */

    const handleInputText = (e: ChangeEvent<HTMLInputElement>) => {
        setInputText(e.target.value);
    };

    /**
     * Opens the detail popup,    On the click of "View More Details".
     */
    const openDetailPopup = () => {
        setIsDetailPopupOpen(true);
    };

    /**
     * This function is called on the click of "Update Status".
     */
    const openUpdateStatus = () => {
        setIsDetailPopupOpen(false);
        setIsUpdateStatusPopupOpen(true);
    };

    const gridStyle = { minHeight: 540 };

    /**
     * Handles the change event of the start date.
     * @param {Date | null} newDate
     */
    const handleStartDateChange = (newDate: Date | null) => {
        // Corrected to ensure only a Date is assigned
        const start: string = newDate?.toISOString() || '';
        setIsCorrectDate(selectedEndDate ? new Date(selectedEndDate.toISOString()) < new Date(start) : false);
        // comparing the date and provide the error message
        if (selectedEndDate?.toISOString() && new Date(selectedEndDate?.toISOString()) < new Date(start)) {
            toast.error('Start Date should be less than the End Date');
        }
        if (newDate !== null) {
            setSelectedStartDate(newDate);
        }
    };

    /**
     * Handles the change event of the end date.
     * @param {Date | null} newDate - The new end date selected by the user.
     */
    const handleEndDateChange = (newDate: Date | null) => {
        // Corrected to ensure only a Date is assigned
        const end: string = newDate?.toISOString() || '';
        setIsCorrectDate(selectedStartDate ? new Date(selectedStartDate.toISOString()) > new Date(end) : false);
        // comparing the date and provide the error message during the api call is restricted
        if (selectedStartDate?.toISOString() && new Date(selectedStartDate?.toISOString()) > new Date(end)) {
            toast.error('End Date should be greater than Start Date');
        }
        if (newDate !== null) {
            setSelectedEndDate(newDate);
        }
    };

    /**
     * Render Buy Crypto and Sell Crypto .
     * @param {Request} data
     * @return string || true
     */
    const transactionStatus = (data: string) => {
        if (STATUS_COLOR.BUY === data) {
            return 'Buy Crypto';
        } if (STATUS_COLOR.SELL === data) {
            return 'Sell Crypto';
        }
        return true;
    };

    /**
     * It provides specific text based on the data.
     * @param {string} data
     */
    const statusHandle = (data: string) => {
        if (!data) {
            return 'Undefined';
        }
        if (STATUS_COLOR.COMPLETED === data) {
            return 'Completed';
        } if (STATUS_COLOR.PENDING === data) {
            return 'Pending';
        }
        if (STATUS_COLOR.CANCELLED === data) {
            return 'Cancelled';
        }
        return true;
    };

    /**
     * Handles the action to view details for a specific user.
     * @param {IAdminDashBoard} data
     */
    const handleView = (data: IAdminDashBoard) => {
        // This redirects to the user-library page, which renders all the details for the specific user.
        if (data.user_id) {
            navigate(`/${ROUTING_ADMIN_PAGE.LIBRARY}/${data.user_id}`);
        }
    };

    const flags: Record<string, string> = {
        USD,
        Real: BRL,
        EURO: EUR,
        PESO: MXN,
        CAD,
    };

    const columns = [
        {
            name: 'full_name',
            header: 'User Name',
            // minWidth: 50,
            defaultFlex: 1,
            render: ({ data }: { data: IAdminDashBoard }) => (
                <span
                    onClick={() => { handleView(data); }}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleView(data);
                        }
                    }}
                >
                    {data?.full_name}
                </span>
            ),
        },
        {
            name: 'ref_id', header: 'Ref ID', maxWidth: 100, defaultFlex: 1,
        },
        {
            name: 'email', header: 'User Email', maxWidth: 250, defaultFlex: 1,
        },
        // {
        //     name: 'contact', header: 'Phone Number', maxWidth: 1000, defaultFlex: 1,
        // },
        {
            name: 'currency',
            header: 'Currency',
            defaultFlex: 1,
            render: (data: IRowProps) => (
                <div>

                    {data.value && <img src={flags[`${data.value}`]} alt="unitedState" />}
                    {' '}
                    {/* {currencies[data?.rowIndex as number] || defaultCurrency} */}
                    {data.value}
                </div>
            ),
        },
        {
            name: 'amount',
            header: 'Amount/Token',
            // maxWidth: 150,
            defaultFlex: 1,
            render: (data: IRowProps) => (
                <div>
                    {data?.value ? (data.value).toLocaleString() : ''}
                </div>
            ),
        },
        {
            name: 'crypto_type', header: 'Type Of Crypto', maxWidth: 150, defaultFlex: 1,
        },
        {
            name: 'transaction_type',
            header: 'BUY/SELL',
            // maxWidth: 150,
            defaultFlex: 1,
            // eslint-disable-next-line react/no-unused-prop-types
            render: ({ data }: { data: IAdminDashBoard }) => (
                <div className={getStatusColor(data.transaction_type)}>
                    {transactionStatus(data?.transaction_type)}
                </div>
            ),
        },
        {
            name: 'transaction_status',
            header: 'Status',
            // maxWidth: 150,
            defaultFlex: 1,
            // eslint-disable-next-line react/no-unused-prop-types
            render: ({ data }: { data: IAdminDashBoard }) => (
                <div className={getStatusColor(data?.transaction_status)}>
                    {statusHandle(data?.transaction_status)}
                </div>
            ),
        },
        {
            name: 'is_active',
            header: 'Actions',
            // maxWidth: 100,
            defaultFlex: 1,
            render: () => (
                <ActionsMenu openDetailPopup={openDetailPopup || (() => { })} openUpdateStatus={openUpdateStatus || (() => { })} />
            ),
        },
    ];

    /**
     * Handles the click event on a cell.
     * @param {any} event
     * @param {TypeCellProps} cellProps
     */
    const onCellClick = (event: any, cellProps: TypeCellProps) => {
        // When you click on any row, it will display the user's details (cellProps.data)
        const data: IAdminDashBoard = cellProps.data as IAdminDashBoard;
        setState(data);
    };

    /**
     * It will be called when a new page is loaded and set the pageSkipValue.
     * @param {number} page
     */
    const handlePageChange = (page: number) => {
        const skip = (page - 1) * RESULT_PER_PAGE;
        setPageSkipValue(skip);
    };

    return (
        <div className={classes.dashboardWrapper}>
            <AmountTransactedChart openChart={openChart} handleClose={handleClose} />
            {isDetailPopupOpen && (
                <TrackOrderFormModel open={isDetailPopupOpen} setOpen={setIsDetailPopupOpen} openUpdateStatus={openUpdateStatus} state={state} />
            )}
            {isUpdateStatusPopupOpen && (
                <UpdateTransactionStatusModel
                    open={isUpdateStatusPopupOpen}
                    setOpen={setIsUpdateStatusPopupOpen}
                    state={state}
                    setHandleAPIcall={setHandleAPIcall}
                />
            )}
            <Box className={classes.headerTittleStyle}>
                <Typography variant="h2">Dashboard</Typography>
                <Box className={classes.datePickerStyle}>
                    {/* <button type="button" style={{ marginTop: 20 }} onClick={exportCSV}>
                        Export CSV
                    </button> */}
                    {/* <label>Date Range</label> */}
                    {/* <button
                        type="button"
                        disabled={!startDate || !endDate || isCorrectDate}
                        style={{
                            display: 'inline-block',
                            marginTop: 20,
                            padding: '10px 20px',
                            backgroundColor: '#007bff',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: startDate && endDate && !isCorrectDate ? 'pointer' : 'not-allowed',
                            opacity: startDate && endDate && !isCorrectDate ? 1 : 0.5,
                        }}
                        title={!startDate || !endDate ? 'Please select start and end dates' : ''}
                        onClick={(e) => {
                            e.preventDefault();
                            if (startDate && endDate) {
                                window.location.href = `${SERVER_URL}${CSV_ENDPOINT}?start_date=${startDate}&end_date=${endDate}`;
                            }
                        }}
                    >
                        Export CSV
                    </button> */}
                    <Box className={classes.datePickerStyleInner}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Box
                                className={`${classes.datePickerStyleStart} ${classes.datePickerStyleStartBorderRight}`}
                            >
                                <label>Start Date</label>
                                <DatePicker
                                    name="StartDate"
                                    value={selectedStartDate}
                                    onChange={handleStartDateChange}
                                    className={classes.datePickerIconStyle}
                                />
                            </Box>
                            <Box
                                className={`${classes.datePickerStyleStart} ${classes.datePickerStyleStartBorderLeft}`}
                            >
                                <label>End Date</label>
                                <DatePicker
                                    name="EndDate"
                                    value={selectedEndDate}
                                    onChange={handleEndDateChange}
                                />
                            </Box>
                        </LocalizationProvider>
                    </Box>
                </Box>
            </Box>
            <Box className={classes.numberStyleWrapper}>
                <Box className={classes.numberStyles}>
                    <Typography variant="h1">{adminCardData?.active_users}</Typography>
                    <Typography variant="h4">No. of Active Users</Typography>
                </Box>
                <Box className={classes.numberStyles}>
                    <Typography variant="h1">{adminCardData?.refering_partners}</Typography>
                    <Typography variant="h4">No. of referring partners</Typography>
                </Box>
                <Box className={classes.numberStyles}>
                    <Typography variant="h1">{adminCardData?.transactions}</Typography>
                    <Typography variant="h4">No. of Transactions</Typography>
                </Box>
                <Box className={`${classes.numberStyles} ${classes.barChartPointer}`} onClick={handleClickOpen}>
                    {/* <Typography variant="h1">
                        {formatAmount(adminCardData?.total_amount, CURRENCY.USD)}
                    </Typography> */}

                    <BarChart
                        style={{ cursor: 'pointer' }}
                        width={300}
                        height={70}
                        barSize={37}
                        data={chartData}
                    >
                        <XAxis dataKey="name" />
                        <Bar
                            dataKey="currency"
                        >
                            {
                                chartData.map((entry, index) => (
                                    <Cell key={entry.name} fill={chartColor[index]} />
                                ))
                            }
                        </Bar>
                    </BarChart>
                    <Typography variant="h4">Amount Transacted</Typography>
                </Box>
            </Box>
            <Box className={classes.tittleInputFilterWrapperStyle}>
                <Box className={classes.tittleInputFilterStyle}>
                    <Typography variant="h2">Recent Transaction Requests</Typography>
                    <Box className={classes.searchIconWrapperStyle}>
                        <span className={classes.searchIconnStyle}>
                            <SearchIcon />
                        </span>
                        <input
                            type="text"
                            placeholder="Search"
                            value={inputText}
                            onChange={handleInputText}
                        />
                    </Box>
                </Box>
                <Box className={classes.dashboardTabsStyle}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                    >
                        <Tab label="All Requests" {...a11yProps(0)} />
                        <Tab label="Pending Requests" {...a11yProps(1)} />
                        <Tab label="Completed Requests" {...a11yProps(2)} />
                        <Tab label="Cancelled Requests" {...a11yProps(3)} />
                    </Tabs>
                </Box>
            </Box>
            <CustomTabPanel value={value} index={0}>
                {isFetching && <Loader />}
                <CustomDataGrid
                    // GridRefdata={setGridRef}
                    columns={columns}
                    dataSource={renderData}
                    rowHeight={77}
                    headerHeight={46}
                    gridStyle={gridStyle}
                    onCellClick={onCellClick}
                />
                <Pagination
                    totalCount={totalCount}
                    totalPages={totalPages}
                    inputText={inputText}
                    handlePageChange={handlePageChange}
                    resultPerPage={RESULT_PER_PAGE}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                {(isFetching || cardFetching) && <Loader />}
                <CustomDataGrid
                    // GridRefdata={setGridRef}
                    columns={columns}
                    dataSource={renderData}
                    rowHeight={77}
                    headerHeight={46}
                    gridStyle={gridStyle}
                    onCellClick={onCellClick}
                />
                <Pagination
                    totalCount={totalCount}
                    totalPages={totalPages}
                    inputText={inputText}
                    handlePageChange={handlePageChange}
                    resultPerPage={RESULT_PER_PAGE}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                {isFetching && <Loader />}
                <CustomDataGrid
                    // GridRefdata={setGridRef}
                    columns={columns}
                    dataSource={renderData}
                    rowHeight={77}
                    headerHeight={46}
                    gridStyle={gridStyle}
                    onCellClick={onCellClick}
                />
                <Pagination
                    totalCount={totalCount}
                    totalPages={totalPages}
                    inputText={inputText}
                    handlePageChange={handlePageChange}
                    resultPerPage={RESULT_PER_PAGE}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
                {isFetching && <Loader />}
                <CustomDataGrid
                    // GridRefdata={setGridRef}
                    columns={columns}
                    dataSource={renderData}
                    rowHeight={77}
                    headerHeight={46}
                    gridStyle={gridStyle}
                    onCellClick={onCellClick}
                />
                <Pagination
                    totalCount={totalCount}
                    totalPages={totalPages}
                    inputText={inputText}
                    handlePageChange={handlePageChange}
                    resultPerPage={RESULT_PER_PAGE}
                />
            </CustomTabPanel>
        </div>
    );
}

export default AdminDashboard;
