import { gql } from 'graphql-request';

const REFERRAL_USER_LIST = (referal_code: string | undefined) => gql`
query {
    adminUserReferrals(referral_code: "${referal_code}") {
      pageInfo {
        skip
        take
        total_count
        has_next_page
      }
      data {
        date_of_referral
        user_name
        no_of_transactions
      }
    }
  }
  `;

export default {
    REFERRAL_USER_LIST,
};
